<template>
  <div>
    <template v-if="value.content && value.content.length">
      <div
        class="row no-gutters align-items-stretch"
        v-bind:style="{
          gap: `${value.style.rowGap}px ${value.style.columnGap}px`,
        }"
      >
        <div
          class="col"
          v-for="(item, itemIndex) in value.content"
          v-bind:key="item.id"
        >
          <WeCard class="h-100 text-center">
            <!-- Remove -->
            <div class="position-absolute" style="right: 12px; top: 12px">
              <button
                class="btn btn-sm btn-danger"
                v-on:click="onRemove(item, itemIndex)"
              >
                <i class="fas fa-trash fa-sm"></i>
              </button>
            </div>
            <!-- ./Remove -->

            <!-- Image -->
            <div class="d-block mb-2">
              <img
                v-bind:src="item.image || 'img/no-image.png'"
                width="100px"
              />
            </div>
            <!-- ./Image -->

            <!-- Product Name -->
            <div class="d-block">
              <small class="font-weight-bold">{{ item.name }}</small>
            </div>
            <!-- ./Product Name -->

            <div class="d-block">
              <span class="font-weight-bold text-danger">{{
                (item.discount_price !== 0 ? item.discount_price : item.price)
                  | moneyFormat
              }}</span>
            </div>
          </WeCard>
        </div>
      </div>
    </template>
    <WeSearchInput
      class="mt-3"
      placeholder="Ürün Ara"
      result-prop="name"
      name="product"
      v-on:on-select="selectProduct($event)"
    />
  </div>
</template>
<script>
export default {
  name: "ProductColumn",
  props: {
    value: {
      default: () => {},
    },
  },
  model: {
    prop: "value",
  },
  methods: {
    selectProduct(product) {
      const { id, name, image, sku_no, price, discount_price } = product;

      if (this.computedData.content === null) this.computedData.content = [];
      if (this.computedData.content.find((item) => item.id == id)) return;

      this.computedData.content.push({
        id,
        name,
        image,
        sku_no,
        price,
        discount_price,
      });
    },
    onRemove(prod, prodIndex) {
      this.$swal({
        title: `${prod.name} adlı ürünü listeden kaldırmak istiyor musunuz ?`,
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
        icon: "warning",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.value.content.splice(prodIndex, 1);
        }
      });
    },
  },
  computed: {
    computedData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>